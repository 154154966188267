import React from 'react';
import { theme } from '../theme';
import { Footer } from '../components/Footer';
import { Button } from '../components/Button/Button';
import { Heading } from '../components/Text/Heading';
import { Formik, Form } from 'formik';
import { TextInput } from '../components/Inputs/TextInput';
import { Label } from '../components/Inputs/Label';
import { Social } from '../components/Social';
import { MultiToggle } from '../components/Inputs/MultiToggle';
import GatsbyImage from 'gatsby-image';
import { graphql } from 'gatsby';
import { Container } from '../components/Container';
import { Text } from '../components/Text/Text';
import { Layout } from '../layouts';
import { AccentHeading } from '../components/Text/AccentHeading';
import axios from 'axios';
import { Fade } from '../components/Animations/Fade';
import { Head } from '../components/Head';

export interface ContactProps {
  data: GatsbySchema.Query;
}

export default class Contact extends React.Component<ContactProps> {
  render() {
    return (
      <Layout>
        <Head
          title="Contact Us"
          description="Contact The Palmer Group About Your Next Digital Project"
        />
        <Container padding={['all']}>
          <div
            css={{
              marginBottom: '6rem',
            }}
          >
            <AccentHeading>Contact Us</AccentHeading>
            <Heading component="h1">Let's Talk</Heading>
            <Text
              size={0}
              css={{
                marginTop: '1rem',
                maxWidth: 600,
              }}
            >
              Got a project? Give us a shout below and we'll be in touch.
            </Text>
          </div>

          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              [theme.media.medium]: {
                flexDirection: 'row',
              },
            }}
          >
            <div
              css={{
                [theme.media.medium]: {
                  width: '50%',
                },
                [theme.media.large]: {
                  width: '60%',
                },
              }}
            >
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  info: '',
                  services: [],
                }}
                onSubmit={(values, { setSubmitting, setStatus }) => {
                  axios
                    .request({
                      url: 'https://api.formik.com/v1/form/5c4716f0579e530001e902fb/submit',
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      data: {
                        'form-name': 'contact',
                        ...values,
                      },
                    })
                    .then(
                      () => {
                        setSubmitting(false);
                        setStatus('Success');
                        window.scrollTo(0, 0);
                      },
                      e => {
                        setSubmitting(false);
                        console.log(e);
                      }
                    );
                }}
              >
                {({ status, values, setFieldValue }) => (
                  <>
                    {status === 'Success' ? (
                      <Fade>
                        <div
                          css={{
                            color: theme.color.blue,
                            fontWeight: theme.bold,
                            fontSize: '3rem',
                            lineHeight: '1',
                            letterSpacing: '-.04em',
                          }}
                        >
                          Thanks! We'll be in touch.
                        </div>
                      </Fade>
                    ) : (
                      <Form>
                        <TextInput
                          css={{ marginBottom: '1rem' }}
                          name="name"
                          label="Name"
                          required
                        />
                        <TextInput
                          css={{ marginBottom: '1rem' }}
                          name="email"
                          label="Email"
                          type="email"
                          required
                        />
                        <TextInput
                          css={{ marginBottom: '1rem' }}
                          name="info"
                          label="About Your Project"
                          component="textarea"
                          rows={12}
                          required
                        />
                        <MultiToggle
                          label="Services (optional)"
                          css={{ marginBottom: '1rem' }}
                          options={[
                            'User Experience',
                            'Interactive Prototyping',
                            'Design Systems & Workflows',
                            'Data Visualization',
                            'Visual Design',
                            'Product Design',
                            'Branding & Positioning',
                          ]}
                          onChange={value => {
                            setFieldValue('services', value);
                          }}
                          value={values.services}
                        />
                        <Button css={{ marginBottom: '2rem' }} type="submit">
                          Submit
                        </Button>
                      </Form>
                    )}
                  </>
                )}
              </Formik>
            </div>
            <div
              css={{
                position: 'relative',
                width: '100%',
                [theme.media.medium]: {
                  paddingLeft: '2rem',
                  width: '50%',
                },
                [theme.media.large]: {
                  width: '40%',
                },
              }}
            >
              <div
                css={{
                  marginBottom: '2rem',
                }}
              >
                <Label component="span">Follow</Label>
                <Social />
              </div>
              <div
                css={{
                  marginBottom: '2rem',
                }}
              >
                <Label component="span">Contact</Label>
                <div>
                  <a href="mailto:info@palmer.net">info@palmer.net</a>
                </div>
              </div>
              <div
                css={{
                  position: 'relative',
                }}
              >
                <GatsbyImage
                  style={{ display: 'block' }}
                  sizes={this.props.data.file.childImageSharp.sizes}
                />
                <div
                  css={{
                    position: 'absolute',
                    top: '1rem',
                    left: '1rem',
                    padding: '1rem',
                    background: theme.color.white,
                    boxShadow: `${theme.shadow.medium}, ${theme.shadow.large}`,
                  }}
                >
                  <Text css={{ fontWeight: theme.bold }}>The Palmer Group</Text>
                  <Text>33 Irving Place</Text>
                  <Text>New York, NY 10003</Text>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Footer />
      </Layout>
    );
  }
}

export const ContactQuery = graphql`
  query contactPageQuery {
    file(relativePath: { eq: "map.png" }) {
      childImageSharp {
        sizes(maxWidth: 700, maxHeight: 700) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`;
