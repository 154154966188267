import React, { PureComponent } from 'react';
import { Transition, animated, config as cfg, SpringProps } from 'react-spring';
import { SpringConfig } from 'react-flip-toolkit';

const wrap = (child: any, styles: any) => {
  styles = { willChange: Object.keys(styles).join(','), ...styles };
  if (!child || !(animated as any)[child.type as any]) {
    // Wrap components into animated divs
    return <animated.div style={{ ...styles }}>{child}</animated.div>;
  }
  // Otherwise inject styles into existing component-props
  const Component = (animated as any)[child.type];
  const props = {
    ...child.props,
    style: {
      ...child.props.style,
      ...styles,
    },
  };
  return <Component {...props} />;
};

export interface FadeProps {
  show?: boolean;
  config?: SpringConfig;
  from?: any;
  to?: any;
  enter?: any;
  leave?: any;
}

export class Fade extends PureComponent<FadeProps> {
  render() {
    const {
      children,
      show = true,
      config = (cfg as any).fast,
      from = { opacity: 0 },
      enter = { opacity: 1 },
      leave = { opacity: 0 },
      ...rest
    } = this.props;
    const result = (styles: any) => wrap(children, styles);
    return (
      <Transition
        config={config}
        native
        keys={show.toString()}
        {...rest}
        from={from}
        enter={enter}
        leave={leave}
        children={show ? result : undefined}
      />
    );
  }
}
