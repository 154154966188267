import React from 'react';
import { theme } from '../../theme';
import { select as $, css as glamorCss, CSSProp } from 'glamor';
import { Label } from './Label';
import { paramCase } from 'change-case';

export interface MultiToggleProps {
  label: string;
  options: string[];
  value: string[];
  onChange: (selected: string[]) => void;
  css?: CSSProp;
}

const inputStyle = {
  position: 'absolute',
  opacity: 0,
  pointerEvents: 'none',
};

const interactionStyles = [
  // focus
  //   $("&:focus+label::after", {
  //     borderColor: theme.color.blue
  //   }),
  // hover
  $('&:hover + label::after', {
    backgroundColor: theme.color.grayLightest,
  }),
  // selection
  $('&:checked + label::after', {
    background: theme.color.green,
  }),
];

const labelStyle = {
  display: 'block',
  marginRight: '.5rem',
  marginTop: '.5rem',
  '&::after': {
    background: theme.color.offWhite,
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'border, background-color .2s ease-in-out',
    willChange: 'border, background-color',
    padding: '.5rem 1rem',
    fontSize: '.875rem',
  },
};

export const MultiToggle: React.SFC<MultiToggleProps> = ({
  label,
  options,
  value,
  onChange,
  ...rest
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onChange([...value, e.target.value]);
    } else {
      onChange(value.filter(option => option !== e.target.value));
    }
  };

  const toggles = options.map(option => {
    const checked = value.includes(option);
    const id = paramCase(option);
    return (
      <div key={id} css={{ position: 'relative' }}>
        <input
          {...glamorCss([inputStyle, interactionStyles])}
          id={id}
          onChange={handleChange}
          type="checkbox"
          value={option}
          name={id}
          checked={checked}
        />
        <label
          css={{
            ...labelStyle,
            '&::after': {
              ...labelStyle['&::after'],
              content: option,
            },
          }}
          htmlFor={id}
        >
          <div
            css={{
              display: 'inline-block',
              //visually hidden
              position: 'absolute',
              overflow: 'hidden',
              clip: 'rect(0 0 0 0)',
              height: '1px',
              width: '1px',
              margin: '-1px',
              padding: 0,
              border: 0,
            }}
          >
            {option}
          </div>
        </label>
      </div>
    );
  });

  return (
    <div {...rest}>
      <Label>{label}</Label>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          marginTop: '-.5rem',
        }}
      >
        {toggles}
      </div>
    </div>
  );
};
