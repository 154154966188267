import React from 'react';
import { Field, FieldProps } from 'formik';
import { Label } from './Label';
import { theme } from '../../theme';
import { CSSProp } from 'glamor';

const styles = {
  appearance: 'none',
  WebkitAppearance: 'none',
  background: theme.color.offWhite,
  padding: '1rem',
  border: 'none',
  width: '100%',
  maxWidth: '70ch',
  display: 'block',
  fontSize: '1rem',
};

export const TextInput: React.SFC<
  {
    name: string;
    component?: string | React.ComponentType;
    label: string;
    css?: CSSProp;
  } & (React.HTMLProps<HTMLInputElement> | React.HTMLProps<HTMLTextAreaElement>)
> = ({ css, component: Component = 'input', name, label, ...rest }) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <div css={{ marginBottom: '1rem' }}>
        <Label>{label}</Label>
        <Component css={{ ...styles, ...css }} {...field} {...rest} />
      </div>
    )}
  </Field>
);
